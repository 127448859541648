<script setup>
	const { card } = defineProps({
		card: {
			type: Object,
			default: () => ({}),
		},
		attributes: {
			type: Object,
			default: () => ({}),
		},
	});
</script>

<template>
	<div class="card fragment">
		<figure v-if="card?.icon" class="card-image-wrapper">
			<ProjectIcon :icon="card?.icon?.src" :alt="card?.icon?.alt" :border="false" :width="60" :height="60" />
		</figure>

		<MessHtml v-if="card?.title" :html="card.title" class="card-title" tag="h4" />

		<MessHtml v-if="card?.body" :html="card?.body" class="rich-text" tag="article" />

		<MessLink v-if="card?.link && !card?.useButton" :href="card.link.url" class="right-arrow" :title="card.link?.title">
			<ProjectSvg type="ArrowRightWithCircle" />
		</MessLink>

		<template v-if="card?.useButton">
			<ProjectButton v-if="card?.button" :button="card.button?.url ? card.button : card?.link" class="red" />
			<MessLink v-else-if="card?.link" :href="card.link.url" class="right-arrow" :title="card.link?.title">
				<ProjectSvg type="ArrowRightWithCircle" />
			</MessLink>
		</template>
	</div>
</template>

<style lang="scss">
	.card.fragment {
		margin-top: 30px;
		padding: 20px;
		background-color: var(--white);
		transition: all var(--card-reveal);
		transition-delay: 250ms;

		@media (min-width: $mobile) {
			max-width: 460px;
			margin-top: 0;
			margin: 15px;
			padding: 40px;

			align-self: self-start;

			&:nth-of-type(even) {
				align-self: self-end;
			}
		}

		.card-image-wrapper {
			position: relative;
			width: 80px;
			height: 80px;
			margin-bottom: 20px;

			.cac-icon {
				width: 80px;
				height: 80px;
				border: 1px solid var(--gray-500);
				border-radius: 100%;

				.mess-image {
					position: absolute;
					width: 48px;
					height: 48px;

					img {
						width: 100%;
						height: 100%;
					}
				}
			}
		}

		.card-title {
			margin-bottom: 20px;
			font-size: calc(26 / var(--base-fs) * 1rem);
			font-weight: 900;
			line-height: 0.98;
			letter-spacing: 0.72px;

			@media (min-width: $mobile) {
				margin-bottom: 30px;
				font-size: calc(36 / var(--base-fs) * 1rem);
			}
		}

		.right-arrow {
			width: fit-content;
			svg,
			circle,
			path {
				transition: var(--transition);
			}

			&:hover {
				svg {
					circle {
						fill: var(--red);
					}
					path {
						stroke: var(--white);
					}
				}
			}
		}
	}

	.v-aos-before-enter {
		.card.fragment {
			transform: translateY(15%);
			opacity: 0;
		}
	}

	.v-aos-entered {
		.card.fragment {
			transform: translateY(0%);
			opacity: 1;
		}
	}
</style>
